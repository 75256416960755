
import { defineComponent, reactive } from '@vue/composition-api';
import ActivityBoilerPlate from './m-activity-boilerplate.vue';

export default defineComponent({
  components: {
    ActivityBoilerPlate
  },
  setup() {
    const studentData = reactive({});
  }
});
